import { useEffect, useState } from "react";

import { useCore } from "~/hooks/useCore";
import { useShopify } from "~/hooks/useShopify";
import { useCheckout } from "~/hooks/useCheckout";
import { useSessionCustomer } from "~/hooks/useSession";

/**
 * When a checkout has been created, and the customer is logged in, associate the
 * checkout with the customer.
 *
 * This is a temporary hack to get association to work prior to cutting over
 * to the new cart API, which does not require this.
 */
export function CheckoutAssociator() {
  const {
    graphql: {
      mutations: { CHECKOUT_CUSTOMER_ASSOCIATE },
    },
  } = useCore();

  const { checkout, setCheckout, getCheckout } = useCheckout();

  const { useMutation } = useShopify();

  const [checkoutCustomerAssociate] = useMutation(CHECKOUT_CUSTOMER_ASSOCIATE);

  const customer = useSessionCustomer();

  const [hasAssociated, setHasAssociated] = useState(false);

  useEffect(() => {
    // No checkout yet, cannot associate.
    if (!checkout) return;

    // No customer yet, cannot associate.
    if (!customer) return;

    // Already associated, do not associate again.
    if (hasAssociated) return;

    // Associate the customer and fetch a fresh checkout.
    checkoutCustomerAssociate({
      variables: {
        checkoutId: checkout.id,
        customerAccessToken: customer.customerAccessToken,
      },
    }).then(() => getCheckout(true));

    // Stop the association running more than once.
    setHasAssociated(true);
  }, [
    customer,
    checkout,
    getCheckout,
    setCheckout,
    hasAssociated,
    checkoutCustomerAssociate,
  ]);

  return null;
}
