import React from "react";

import { UIProvider } from "./ui";
import { AppProvider } from "./app";
import { ShopProvider } from "./shop";
import { SearchProvider } from "./search";
import { ShopifyProvider } from "./shopify";
import { CheckoutProvider } from "./checkout";
import { FirebaseProvider } from "./firebase";

import { SanityProvider } from "./SanityProvider";

import config from "../../../config.default.mjs";

export interface GlobalProvidersProps {
  children: React.ReactNode;
}

export const GlobalProviders: React.FC<GlobalProvidersProps> = ({
  children,
}) => {
  return (
    <SanityProvider>
      <AppProvider config={config}>
        <SearchProvider>
          <FirebaseProvider config={config?.services?.firebase}>
            <ShopProvider>
              <CheckoutProvider>
                <UIProvider>
                  <ShopifyProvider>{children}</ShopifyProvider>
                </UIProvider>
              </CheckoutProvider>
            </ShopProvider>
          </FirebaseProvider>
        </SearchProvider>
      </AppProvider>
    </SanityProvider>
  );
};
