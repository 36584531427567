import { useApp } from "@hooks/useApp";
import { useCheckout, useCheckoutContext } from "@hooks/useCheckout";
import { useCore } from "@hooks/useCore";
import { useLocalisationContext } from "@hooks/useLocalisation";
import { useSale } from "@hooks/useSale";
import { globalHistory } from "@reach/router";
import React, { useEffect } from "react";

import type { GatsbyLocation } from "~/types/gatsby";

interface InputProps {
  location: GatsbyLocation;
  navigationRootPath?: string;
  children: React.ReactNode;
}

type OutputProps = InputProps;

export const withApp =
  (Component: React.FC<OutputProps>) =>
  ({ location, children, navigationRootPath }: InputProps) => {
    const {
      helpers: { storage },
    } = useCore();
    const {
      config: {
        settings: { keys },
      },
    } = useApp();
    const { checkout } = useCheckoutContext();
    const { getCheckout, createCheckout } = useCheckout();
    const { isSaleActive } = useSale();
    const isSale = isSaleActive();
    const { currentLocale, isResolvingPreferredLocale } =
      useLocalisationContext();
    const { defaultCountryCode } = currentLocale || {};

    useEffect(() => {
      return globalHistory.listen(({ location }) => {
        const scrollRestorationRoutes = ["/products/", "/collections/"];

        if (
          !scrollRestorationRoutes.some((route) =>
            location.pathname.match(route),
          )
        ) {
          storage.remove(keys.collection_scroll_position);
        }
      });
    }, []);

    // Create a new checkout if the current checkout does not exist or match the right country.
    useEffect(() => {
      const checkoutCountryCode = checkout?.buyerIdentity?.countryCode;
      if (
        checkoutCountryCode === defaultCountryCode ||
        isResolvingPreferredLocale
      ) {
        return;
      }

      createCheckout(defaultCountryCode);
    }, [checkout, defaultCountryCode, isResolvingPreferredLocale]);

    // Retrieve and save the checkout on page load and if sale mode changes.
    useEffect(() => {
      getCheckout(true);
    }, [isSale]);

    return (
      <Component location={location} navigationRootPath={navigationRootPath}>
        {children}
      </Component>
    );
  };
