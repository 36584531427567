import CartDrawer from "@components/Cart/Drawer/CartDrawer";
import CountdownTimer from "@components/CountdownTimer/CountdownTimer";
import { FooterSection } from "@components/Footer/FooterSection";
import Header from "@components/Header/Header";
import React from "react";
import AppStyles from "./AppStyles";
import { withApp } from "./withApp";

export const App = withApp(({ children, location, navigationRootPath }) => (
  <>
    <AppStyles />
    <Header location={location} navigationRootPath={navigationRootPath} />
    <main className="flex-1 pt-[86px] md:pt-[122px]" id="content-main">
      <CountdownTimer />
      {children}
    </main>
    <FooterSection />
    <CartDrawer />
  </>
));
